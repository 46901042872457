import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import logo from "../../src/assets/images/logo-gameblitz.png";
import { FaBars, FaTimes } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";

const Header = ({ category }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [cate, setCate] = useState([]);
  console.log(cate, "cate");
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };
  const [nav, setNav] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/api/games");
        console.log(response.data, "===");
        setCate(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  return (
    <>
      <div class="top-0 py-1 lg:py-2 w-full bg-transparent lg:relative z-50 bg-gradient-to-r from-yellow-300 to-slate-500">
        <nav class="z-10 sticky top-0 left-0 right-0 max-w-4xl xl:max-w-5xl mx-auto px-5 py-2.5 lg:border-none lg:py-4">
          <div class="flex items-center justify-between">
            <Link to="/">
              <div className="hover:text-2xl">
                <img className="w-[150px] hover:scale-125" src={logo} alt="logo" />
              </div>
            </Link>
            <div nClick={() => setNav(!nav)} class="hidden lg:block">
              <div class="flex space-x-10 text-base font-bold text-black/60 text-zinc-800">
                <div class="flex flex-wrap justify-center gap-6">
                  {/* <a class="relative" href="#">
                    <span class="absolute top-0 left-0 mt-1 ml-1 h-full w-full rounded bg-black"></span>
                    <span class="fold-bold relative inline-block h-full w-full rounded border-2 border-black bg-white px-3 py-1 text-base font-bold text-black transition duration-100 hover:bg-yellow-300 hover:text-gray-900">
                      Home
                    </span>
                  </a> */}
                  <a class="relative" href="#">
                    <span class="absolute top-0 left-0 mt-1 ml-1 h-full w-full rounded bg-black"></span>
                    <span
                      onClick={() => setNav(!nav)}
                      class="fold-bold relative inline-block h-full w-full rounded border-2 border-black bg-white px-3 py-1 text-base font-bold text-black transition duration-100 hover:bg-yellow-300 hover:text-gray-900"
                    >
                      Category
                    </span>
                  </a>
                </div>
              </div>
            </div>
            {nav ? (
              <div
                onClick={() => setNav(!nav)}
                className="bg-black/80 fixed w-full h-screen z-10 top-0 left-0"
              >
                {" "}
              </div>
            ) : (
              ""
            )}
            <div
              className={
                nav
                  ? "fixed top-0 left-0 w-[300px] h-screen bg-white z-10 duration-300 overflow-auto"
                  : "fixed top-0 left-[-100%] w-[300px] h-screen bg-white z-10 duration-300 overflow-auto"
              }
            >
              <AiOutlineClose
                onClick={() => setNav(!nav)}
                size={30}
                className="absolute right-4 top-4 cursor-pointer"
              />
              <h2 className="text-2xl p-4">
              <span className="font-bold text-violet-950">Gameblitz</span>
              </h2>
              <nav>
                <ul className="flex flex-col p-4 text-grey-800 overflow-auto-x">
                  {cate.map((game) => (
                    <>
                    <li key={game.category} className="text-xl py-4 flex overflow-auto font-semibold">
                    <Link
                  to={`/category/${game.category}`}
                  className="text-blue-500 "
                >{game.category}</Link>
                    </li>
                    <hr className="text-yellow-300 bg-slate-500"/>
                    </>
                  ))}
                </ul>
              </nav>
            </div>
            <div class="flex items-center justify-center lg:hidden">
              <button
                onClick={toggleMobileMenu}
                class="focus:outline-none text-slate-200 dark:text-white"
              >
                {isMobileMenuOpen ? (
                  <FaTimes className="text-2xl text-slate-800 dark:text-white focus:outline-none active:scale-110 active:text-red-500" />
                ) : (
                  <FaBars className="text-2xl text-slate-800 dark:text-white focus:outline-none active:scale-110 active:text-red-500" />
                )}
              </button>
            </div>
          </div>
        </nav>
      </div>

      {/* Sidebar */}
      {isMobileMenuOpen && (
        <div className="fixed inset-0 z-50 bg-gray-900 bg-opacity-50">
          <div className="absolute inset-y-0 left-0 w-64 bg-white shadow-lg">
            {/* Sidebar content */}
            <div className="flex flex-col h-full">
              {/* Sidebar header */}
              <div className="flex items-center justify-between px-4 py-2 border-b">
                <h2 className="text-lg  text-violet-950 font-bold">Gameblitz</h2>
                <button
                  onClick={toggleMobileMenu}
                  className="text-gray-500 hover:text-gray-700 focus:outline-none focus:text-gray-700"
                >
                  <FaTimes className="w-6 h-6" />
                </button>
              </div>
              {/* Category links */}
              <div className="flex flex-col p-4 gap-5">
              {cate.map((game) => (
                <Link to={`/category/${game.category}`}>
                <a  key={game.category} class="relative">
                  <span class="absolute top-0 left-0 mt-1 ml-1 h-full w-full rounded bg-black"></span>
                  <span class="fold-bold relative inline-block h-full w-full rounded border-2 border-black bg-white px-3 py-1 text-base font-bold text-black transition duration-100 hover:bg-yellow-400 hover:text-gray-900">
                  {game.category}
                  </span>
                </a>
                  </Link>
                   ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
