import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import { AiOutlineRollback } from "react-icons/ai";
import Spinner from './Spinner';
function CategoryPage() {
  const { category } = useParams();
  const [games, setGames] = useState([]); 
  const [categoryName, setCategoryName] = useState([]); 
  const [loading, setLoading] = useState(true);
  
 console.log(categoryName,)
  useEffect(() => {
    const fetchGames = async () => {
      try {
        const response = await axios.get("/api/games");
        const gamesData = response.data;

        // Filter games based on category if categoryName is provided
        const filteredGames = category
          ? gamesData.filter((game) => game.category === category)
          : gamesData;



          setCategoryName(filteredGames[0].category)
        setGames(filteredGames[0].games);
        setLoading(false); // Set loading to false after data is fetched and set
      } catch (error) {
        console.error("Error fetching games:", error);
        setGames([]); // Reset games array on error
        setLoading(false); // Set loading to false on error
      }
    };

    fetchGames();
  }, [category]); // Re-run effect when category changes

  return (
    <div className=" mx-auto px-4 py-5 bg-black">
    {loading ? (
      <>
      <div>
     <Spinner/>
      </div>
      </>


    ) : (
      <div>

        <div className="mx-auto flex items-center justify-center gap-10 px-2 py-5">
          <ul className="border-purple-800 hover:border-yellow-300 rounded-3xl bg-slate-400 group flex-1 -skew-x-[10deg] transform border">
            <li className="m-4 skew-x-[10deg] transform bg-transparent px-9 text-center first-letter:uppercase">
              <b className="block text-lg text-[#2e1065]">{categoryName}</b>
            </li>
          </ul>
        </div>
        <div className='flex gap-3 text-white sm:ml-8 p-4'>
        <Link to="/Home"> <div className=' cursor-pointer'>  < AiOutlineRollback size={30}/></div> </Link>
   <div className='mt-1 font-semibold'> Back</div>
        </div>
        <div className="lg:mx-6 lg:mt-8 grid lg:grid-cols-4 md:grid-cols-4 sm:grid-cols-3 grid-cols-2 gap-4 lg:gap-8">
          {games.map((game) => (
            <div
            key={game.gameid}
            className={`${game.category} group h-[200px] sm:h-[300px] overflow-hidden relative shadow-lg max-w-xs rounded-xl`}
          >
            <img
              className="block group-hover:opacity-40 transition-opacity duration-700 h-full w-full object-cover"
              src={game.img6}
              alt={game.gameurl}
            />
            <div className="absolute bg-black flex items-center group-hover:-top-0 group-hover:opacity-100 duration-700 top-full right-0 w-full opacity-0 h-1/2 transition-all">
              <img
                className="block group-hover:opacity-40 transition-opacity duration-700 h-full w-full object-fill"
                src={game.img6}
                alt={game.gameurl}
              />
            </div>
            <div className="absolute  duration-700 bg-gradient-to-r from-yellow-300 to-slate-500 text-white block left-0 right-0 top-full text-base h-1/2 w-full opacity-50 transition-all group-hover:top-1/2 group-hover:opacity-100">
              <div className="py-4 text-xs px-7">
                <div className="text-xl font-bold">{game.gamename}</div>
                <div className="whitespace-nowrap overflow-hidden overflow-ellipsis relative z-20">
                  <span className="uppercase text-slate-800 whitespace-nowrap text-xs md:text-sm">
                    Category:
                  </span>
                  <span className="whitespace-nowrap overflow-hidden text-zinc-900 font-semibold text-lg overflow-ellipsis relative z-20">
                    <a href={game.publisherlink}> {game.category}</a>
                  </span>
                </div>
              </div>
              <div className="absolute left-0 pl-7 pt-1">
                <Link
                  to={`/games/${game.id}/${game.category}`}
                  key={game.id}
                >
                  <button className="px-4 text-base block text-white rounded-sm border-2 border-opacity-20 bg-violet-950 duration-700">
                    Play
                  </button>
                </Link>
              </div>
            </div>
          </div>
          ))}
        </div>
      </div>
    )}
  </div>
);
}

export default CategoryPage;