import React, { useEffect, useState } from 'react';
import Header from './Header';
import GameCarousel from './GameCarousel';
import AllGames from './AllGames';
function HomePage() {

  
  return (
    <div className="h-screen flex flex-col overflow-hidden">
      <Header  />
      <div className="flex-grow overflow-y-auto bg-black">
      <div className="max-w-[1640px] mx-auto p-4"  id="Heading">
      <div className="max-h-[500px] relative">
        <div className="absolute w-full h-full text-grey-200 max-h-[500px] bg-black/40 flex flex-col justify-center ">
          <h1 className="px-4 text-4xl sm:text-5xl md:text-6xl lg:7xl font-bold ml-28  text-yellow-300   ">
            Play<span className=" text-white"> unlimited </span>
          </h1>
          <h1 className="px-4 text-4xl ml-28 sm:text-5xl md:text-6xl lg:7xl font-bold text-yellow-300">
          GAMES
          </h1>
      </div>
      <img
          className="w-full max-h-[500px] object-cover "
          src="https://images4.alphacoders.com/129/1299410.jpg"
          alt="logo"
        />
    </div>
    </div>
    <AllGames/>
    </div>
 
    </div>
  );
}

export default HomePage;
