import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import Cookies from "js-cookie";
import image from "../assets/images/1329595 (1).jpeg"
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Login = () => {
  const [msisnd, setMsisdn] = useState  ("");
  const [errorModal, setErrorModal] = useState(false);
  const navigate=useNavigate()
  const [errInfo, setErrInfo] = useState({
    message: "",
    redirectLink: "",
  });
  const nav = useNavigate();
  const { t } = useTranslation();
  // if link already exist redierct user to link page
  // useEffect(() => {
  //   const link = Cookies.get("gameBlitz-user");
  //   if (link === "null" || link !== undefined) {
  //     return window.location.replace(link);
  //   }
  // }, []);

  const handelSubmit = async (e) => {
    e.preventDefault();
    if (msisnd.length <= 5) return toast.error("Enter a valid number");
    let ani = "";
    if (msisnd.charAt(0) === "0") {
      ani = msisnd;
    } else {
      ani = "0" + msisnd.replace("251", "");
    }
    
    try {
      const resp = await axios.get(`/api/regester?msisdn=${ani}`);
      const data = resp.data;
      // console.log(data)
      Cookies.set("gameBlitz-user", data.redirectUrl, { expires: 2 });
      return   navigate('/home')
    } catch (e) {
      console.log(e.response)
      if (e.response?.data.result == 0) {
       return toast.error(e.response?.data.msg)
      }
      return toast.error("Network error");
    }
  };

  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <main className="mt-9 m-0 dark:text-white bg-white min-h-screen flex items-center flex-col">
        <div className="w-[200px] sm:w-[41%] h-[200px] sm:h-[300px] rounded-3xl ">
          <img src={image} className="w-full object-cover h-full rounded-3xl" alt="game station" />
        </div>
        <div className="p-2 w-full sm:w-auto sm:min-w-[500px] sm:max-w-3xl h-auto">
          <form
            onSubmit={handelSubmit}
            className="w-full flex flex-col justify-center items-center xs:mt-[-20px]"
          >
            <div className="flex flex-row flex-wrap items-center justify-evenly gap-3">
              <label
                htmlFor="mobile-number"
                className="mb-2 text-pretty font-bold text-xs pt-2 sm:text-lg font-mono text-zinc-700"
              >
                {t("Enter Mobile Number")}
              </label>

              <input
                type="number"
                onChange={(e) =>
                  setMsisdn(e.target.value)
                }
                className="bg-white text-gray-500 w-[80%] xs:space-x-1 sm:w-96 h-9 outline-gray-500 border border-gray-500  font-mono font-bold p-4 text-1xl focus:outline-blue-600"
                id="mobile-number"
                placeholder=""
              />
            </div>
            <button
              type="submit"
              className="mt-4 bg-gradient-to-r from-blue-500 to-purple-600 hover:to-purple-500 text-white font-bold py-2 px-6 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 hover:bg-gradient-to-r hover:from-blue-400 w-[290px] sm:w-[590px] "
            >
              {t("Submit")}
            </button>
          </form>
          <div className="flex flex-col justify-center items-center mt-4 sm:pl-20 sm:pr-20 text-sm">
            <h2 className="font-mono text-1xl text-gray-500 font-bold text-center mb-2">
              {t("By clicking Subscribe, you agree to our")}
            </h2>
            <h3
              onClick={() => {
                nav("/terms");
              }}
              className="text-green-500 border-b-indigo-600 cursor-pointer underline-offset-1"
            >
              {t("Terms and Conditions")}
            </h3>
            <ul className="text-xs">
              <li className="font-serif text-xs sm:text-md text-gray-500  text-center">
                {t("You will subscribe in Game🎯Blitz for 2ETB for 1 day.")}
              </li>
              <li className="font-serif text-xs sm:text-md text-gray-500  text-center">
                {t("Subscribe Now and Get 3 days free access.")}
              </li>
              <li className="font-serif text-xs sm:text-md text-gray-500  text-center">
                {t(
                  "To cancel your subscription, please send STOP to 8761 (forfree)."
                )}
              </li>
              <li className="font-serif text-xs sm:text-md text-gray-500  text-center">
                {t(
                  "To cancel from the website please go to My Account section after logging in and click on unsubscribe."
                )}
              </li>
              <li className="font-serif text-sm sm:text-md text-gray-500  text-center">
                {t(
                  "For any inquiries please contact us at mailto:team@star-tech.website."
                )}
              </li>
            </ul>
          </div>
        </div>
      </main>
      {errorModal && (
     <div>
      error
     </div>
      )}
    </>
  );
};

export default Login;
