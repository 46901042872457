import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from '../src/components/store/store'
import i18n from "i18next"
import {initReactI18next} from "react-i18next"
import en from "./translations/en.json"
import am from "./translations/am.json"
import {
  RouterProvider,
} from "react-router-dom";
i18n.use(initReactI18next).init({
    fallbackLng: "en",
    resources: {
      en: {
        translation: en
      },
      am: {
        translation: am
      },
    },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <Provider store={store}>
     <React.StrictMode>
   <App/>
  </React.StrictMode>
    </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
